.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px; /* Adjust width as needed */
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  z-index: 1000; /* Ensures it stays on top */
}

.sidebar.open {
  transform: translateX(0);
}

.content {
  transition: margin-left 0.3s ease;
  margin-left: 0; /* Ensure content resets */
}

.content.pushed {
  margin-left: 200px; /* Adjust this value to match the sidebar width */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none; /* Initially hidden */
}

.overlay.show {
  display: block;
}

.content .toggle-button {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1001;
}

.toggle-button {
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1001; /* High z-index to float above other components */
  font-size: 24px; /* Adjust size as needed */
  color: #000; /* Adjust color as needed */
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%; /* Sidebar takes full width on small screens */
  }

  .content.pushed {
    margin-left: 100%; /* Content pushed to the right on small screens */
  }
}